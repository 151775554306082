import React, { useContext } from "react";
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { useGetCarePlanAPI } from "../../hooks/useGetCarePlan";
import { useGetCarePlanAccessAPI } from "../../hooks/useGetCarePlanAccessAPI";
import CarePlan from "./CarePlan";
import CarePlanMobile from "./CarePlanMobile";
import { SpinnerContext } from "../../contexts/spinner.context";
import { MY_HEALTH_RECORD_GET_CARE_PLAN } from "../../define.widgets";
import * as HealthRecordAPI from "../../services/HeathRecordAPI";
import { AppContext } from "../../contexts/app.context";
import {
  IA,
  SPL_CarePlan_Name,
  GLOBAL_VARIABLE,
  ICON_PATH,
  WI,
  WI_MEDICAID_PROGRAMID,
  MEDICAID,
  NM,
  NM_MEDICAID_ProgramID,
  WA,
  WA_MD_APPLE,
} from "../../define.constants";

const MyHealthRecordCarePlan = (props) => {
  const { carePlan, isLoaded } = useGetCarePlanAPI();
  const { ltssMemberFlag } = useGetCarePlanAccessAPI();
  const { innerSpinnerHTML } = useContext(SpinnerContext);

  GLOBAL_VARIABLE.genesysIntent = getFieldValue(props.fields, "intent");
  const { MemberStateName, memberLOB, programId } = useContext(AppContext);
  const { setSpinner } = useContext(SpinnerContext);

  const isWIMedicaid =
    memberLOB?.toLowerCase() === MEDICAID &&
    MemberStateName === WI &&
    programId === WI_MEDICAID_PROGRAMID;

  const isWAMedicaid =
    memberLOB?.toLowerCase() === MEDICAID &&
    MemberStateName === WA &&
    programId === WA_MD_APPLE;

  const isNMMedicaid =
    memberLOB?.toLowerCase() === MEDICAID &&
    MemberStateName === NM &&
    programId === NM_MEDICAID_ProgramID;

  const planButtonClick = () => () => {
    var element = document.createElement("a");
    document.body.appendChild(element);

    let byteChars = window.atob(carePlan);
    let byteNum = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i++) {
      byteNum[i] = byteChars.charCodeAt(i);
    }
    let byteAr = new Uint8Array(byteNum);
    let file = new Blob([byteAr], {
      type: "application/pdf;base64",
    });
    let filePath = URL.createObjectURL(file);
    element.href = filePath;
    window.open(element);
  };

  const planButtonClickLtss = () => () => {
    // var element = document.createElement("a");
    // document.body.appendChild(element);

    // let byteChars = window.atob(carePlanLTSS);
    // let byteNum = new Array(byteChars.length);
    // for (let i = 0; i < byteChars.length; i++) {
    //   byteNum[i] = byteChars.charCodeAt(i);
    // }
    // let byteAr = new Uint8Array(byteNum);
    // let file = new Blob([byteAr], {
    //   type: "application/pdf;base64",
    // });
    // let filePath = URL.createObjectURL(file);
    // element.href = filePath;
    // window.open(element);
    setSpinner(true);
    HealthRecordAPI.getCarePlan({
      CareplanName: SPL_CarePlan_Name,
    })
      .then((response) => {
        if (response?.ResultData?.Output) {
          let byteChars = window.atob(response.ResultData.Output);
          let byteNum = new Array(byteChars.length);
          for (let i = 0; i < byteChars.length; i++) {
            byteNum[i] = byteChars.charCodeAt(i);
          }
          let byteAr = new Uint8Array(byteNum);
          let file = new Blob([byteAr], {
            type: "application/pdf;base64",
          });
          let filePath = URL.createObjectURL(file);
          window.open(filePath);
        } else {
          setError({ err: { statusCode: response.ResultCode } });
        }
        setData(response);
      })
      .catch((err) => {
        setError({ err });
      })
      .finally(function () {
        setSpinner(false);
      });
  };

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="carePlan"
        role="tabpanel"
        aria-label="tab-8"
      >
        <div className="row no-tab-title" id="careplanrow">
          <div className="col-12">
            {isLoaded ? (
              MemberStateName === "IA" ||
              isWIMedicaid ||
              isNMMedicaid ||
              isWAMedicaid ? (
                <li className="list-group-item">
                  <div className="carePlanBody">
                    <div className="grid-container">
                      <div className="column-container">
                        <div className="column-header">
                          <Text field={props.fields.carePlanName} />
                        </div>
                        <div
                          className="column-content"
                          style={{ fontSize: "initial" }}
                        >
                          <div>
                            <Text field={props.fields.molinaInternal} />
                          </div>
                          {ltssMemberFlag && (
                            <div>
                              <Text field={props.fields.personCentered} />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="column-container">
                        <div className="column-header">
                          <Text field={props.fields.carePlanType} />
                        </div>
                        <div
                          className="column-content"
                          style={{ fontSize: "initial" }}
                        >
                          <div>
                            <Text field={props.fields.molinaInternal} />
                          </div>
                          {ltssMemberFlag && (
                            <div>
                              <Text field={props.fields.ltss} />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="column-container">
                        <div className="column-header">
                          <Text field={props.fields.tabName} />
                        </div>
                        <div
                          className="column-carePlan-content"
                          style={{ fontSize: "initial" }}
                        >
                          <div>
                            <button
                              id="plan1"
                              className="carePlanbtn"
                              style={{ color: "white" }}
                              onClick={planButtonClick()}
                              src={`${ICON_PATH}/Group_2641.svg`}
                              download="ClaimsHistory.pdf"
                              // alt={item.name}
                              // onClick={() => downloadPDF(item.name)}
                            >
                              <Text field={props.fields.viewCarePlan} />
                            </button>
                          </div>
                          {ltssMemberFlag && (
                            <div>
                              <button
                                id="plan1"
                                className="carePlanbtn"
                                style={{ color: "white" }}
                                onClick={planButtonClickLtss()}
                                src={`${ICON_PATH}/Group_2641.svg`}
                                // alt={item.name}
                                // onClick={() => downloadPDF(item.name)}
                              >
                                <Text field={props.fields.viewCarePlan} />
                              </button>
                            </div>
                          )}

                          {/* {renderData.buttonArray} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                <>
                  <div className="col-md-12 page-subheading mb-4 mt-4">
                    <Text field={props.fields.lblTitle} />
                  </div>
                  {/* <div>
            <iframe
              src={`data:application/pdf;base64,${carePlan}`}
              width="100%"
              height="1140px"
            />
          </div> */}
                  <CarePlan carePlan={carePlan} />
                  <CarePlanMobile
                    carePlan={carePlan}
                    altText={props.fields.lblTitle}
                  />
                </>
              )
            ) : (
              <li className="list-group-item">
                {innerSpinnerHTML?.[MY_HEALTH_RECORD_GET_CARE_PLAN]}
                <div className="row field1">
                  <div className="col-sm-12"></div>
                </div>
              </li>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyHealthRecordCarePlan;
