import React, { useState, useContext } from "react";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import { WhoIsAuthorized } from "../../common/WhoIsAuthorized";
import { AppContext } from "./../../contexts/app.context";
import MemberRewardsForm from "./MemberRewardsForm";
import {
  TX,
  NM,
  MEDICAID,
  TX_MD_STAR_PROGRAMID,
  TX_MD_PLUS_PROGRAMID,
  TX_MD_CHIP_PROGRAMID,
  TX_MD_CHIPPerinate_PROGRAMID,
} from "../../define.constants";

const MemberRewards = (props) => {
  let fields = props.fields;
  const { MemberStateName, memberLOB, programId } = useContext(AppContext);
  const isTexasMedicaidMember =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === TX;
  const isTXStar =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === TX &&
    programId === TX_MD_STAR_PROGRAMID;
  const isTXStarPlus =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === TX &&
    programId === TX_MD_PLUS_PROGRAMID;
  const isTXChip =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === TX &&
    programId === TX_MD_CHIP_PROGRAMID;
  const isTXCHIPPerinate =
    memberLOB?.toLowerCase() === MEDICAID?.toLowerCase() &&
    MemberStateName === TX &&
    programId === TX_MD_CHIPPerinate_PROGRAMID;

  const rewardSubmission = () => {
    if (
      MemberStateName === NM &&
      memberLOB?.toLowerCase() === MEDICAID?.toLowerCase()
    ) {
      window.open(
        "https://www.centennialrewards.com/rewards/landing/",
        "blank"
      );
    } else {
      var formContent = document.getElementById("collapse0");
      formContent.className = " accordion-collapse collapse show";
      var formTitle = document.getElementById("formBtn");
      formTitle.className = "accordion-button";
    }
  };
  return (
    <div className="accordion" id="healthyrewardsBlock">
      {/* Form */}
      <div key="0">
        <div className={`accordion-item cards-tab3`}>
          <div>
            <div className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target={"#collapse0"}
                aria-expanded="false"
                aria-controls={"collapse0"}
                id="formBtn"
              >
                <Text field={fields.rewardSubmissionForm} />
              </button>
            </div>
            <div
              id={"collapse0"}
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#healthyrewardsBlock"
            >
              <div className="accordion-body">
                <div className="accordion-body-desc">
                  {/* Form fields start */}
                  <MemberRewardsForm
                    fields={fields}
                    memberStateName={MemberStateName}
                    memberLOB={memberLOB}
                  />
                  {/* Form fields end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Form Content */}
      <div key="1">
        <div className={`accordion-item last-card-tab3`}>
          <div>
            <div className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target={"#collapse1"}
                aria-expanded="true"
                aria-controls={"collapse1"}
                id="staticBtn"
              >
                <Text field={fields.lblTitle} />
              </button>
            </div>
            <div
              id={"collapse1"}
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-mdb-parent="#healthyrewardsBlock"
            >
              <div className="accordion-body">
                <div className="accordion-body-desc">
                  <div className="row d-flex pb-4">
                    <div
                      className="col-xl-10 col-lg-9 col-md-8 col-sm-8 col-9"
                      style={{
                        font: "normal normal normal 0.8rem/0.96rem campton-medium",
                      }}
                    >
                      <Text
                        className="btn btn-primary ripple-surface"
                        field={fields.description}
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-3 position-relative">
                      <WhoIsAuthorized handleClick={rewardSubmission}>
                        {({ handleClick }) => {
                          return (
                            <button
                              className={`btn btn-primary`}
                              onClick={handleClick}
                            >
                              <Text
                                className="btn btn-primary ripple-surface"
                                field={fields.rewardSubmissionForm}
                              />
                            </button>
                          );
                        }}
                      </WhoIsAuthorized>
                    </div>
                  </div>
                  <div
                    className="pb-2"
                    style={{
                      position: "relative",
                      bottom: "10px",
                      left: "12px",
                      font: "normal normal normal 0.8rem/0.96rem campton-medium",
                    }}
                  >
                    <b>
                      <Text field={fields.listOfAvailableRewards} />
                    </b>
                  </div>

                  <div>
                    {isTexasMedicaidMember ? (
                      isTXStar ? (
                        <RichText field={fields.listOfRewardsTexaxStar} />
                      ) : isTXStarPlus ? (
                        <RichText field={fields.listOfRewardsTexaxStarPlus} />
                      ) : isTXCHIPPerinate ? (
                        <RichText
                          field={fields.listOfRewardsTexaxCHIPPerinatal}
                        />
                      ) : isTXChip ? (
                        <RichText field={fields.listOfRewardsTexaxCHIP} />
                      ) : (
                        <RichText field={fields.listOfRewards} />
                      )
                    ) : (
                      <RichText field={fields.listOfRewards} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberRewards;
